import { render, staticRenderFns } from "./SecondhandGoods.vue?vue&type=template&id=609f0ac4&scoped=true"
import script from "./SecondhandGoods.vue?vue&type=script&lang=js"
export * from "./SecondhandGoods.vue?vue&type=script&lang=js"
import style0 from "./SecondhandGoods.vue?vue&type=style&index=0&id=609f0ac4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "609f0ac4",
  null
  
)

export default component.exports