<template>
  <div class="max-w1200 mrauto">
    <div class="flex ptb30 borb-E6E6E6">
      <div class="per-w50 mr26">
        <el-carousel indicator-position="outside" height="25rem"  ref="carousel"
            @change="changeItem"
            trigger="click">
          <el-carousel-item v-for="item in bannerImg" :key="item">
            <img :src="item" alt="" class="h400 per-w100" />
          </el-carousel-item>
        </el-carousel>
        <div>
          <div class="zs-adv">
            <div id="adv-pad-scroll">
              <!-- <i
                  style="
                    font-size: 30px;
                    display: inline-block;
                    position: relative;
                    top: 40px;
                    cursor: pointer;
                  "
                  class="el-icon-arrow-left"
                  @click="imgLeft()"
                ></i> -->
              <div class="adv-pad" ref="all">
                <div
                  class="adv-pad-item"
                  v-for="(item, index) in bannerImg"
                  :key="index"
                  ref="demoItem"
                  :class="active == index ? 'active' : ''"
                  @click="clickBtn(index)"
                  :style="imgStyle"
                >
                  <img alt="" :src="item" />
                </div>
              </div>
              <!-- <i
                  style="
                    font-size: 30px;
                    display: inline-block;
                    position: relative;
                    left: 370px;
                    top: -50px;
                    cursor: pointer;
                  "
                  class="el-icon-arrow-right"
                  @click="imgRight()"
                ></i> -->
            </div>
          </div>
        </div>
      </div>
      <div class="per-w50">
        <div class="fwb fs-26 col-333333">
          {{ info.title }}
        </div>
        <div class="fs-14 flex a-center j-sb mt14">
          <div>
            <!-- <span class="mr20 ln-bl col-333333"
              >发布时间：{{ info.create_time_text }}</span
            > -->
            <span class="mr20 ln-bl col-333333">信息编号：{{ info.code }}</span>
          </div>
          <div class="flex a-center">
            <div
              class="col-CC0000 flex mr15 a-center cup"
              v-if="info.status == 4 && info.status != 3 && info.status != 10"
            >
              <div class="mr10 w18h18">
                <img src="../../assets/img/jubao.png" alt="" class="w18h18" />
              </div>
              已举报
            </div>
            <div
              class="col-CC0000 mr15 flex a-center cup"
              @click="dialogShow(4)"
              v-if="info.status != 4 && info.status != 3 && info.status != 10"
            >
              <div class="mr10 w18h14">
                <img
                  src="https://pt.baipubang.com/app_icon/index/jubao.png"
                  alt=""
                  class="w18h14"
                />
              </div>
              举报已转
            </div>
            <div
              class="col-CC0000 flex a-center cup"
              @click="dialogShow(1)"
              v-if="info.is_coll == 1 && info.status != 10"
            >
              <div class="mr10 w18h18">
                <img
                  src="https://pt.baipubang.com/app_icon/index/yidainzan.png"
                  alt=""
                  class="w18h18"
                />
              </div>
              已收藏
            </div>
            <div
              class="col-CC0000 flex a-center cup"
              @click="collectClick"
              v-if="info.is_coll != 1 && info.status != 10"
            >
              <div class="mr10 w18h14">
                <img src="../../assets/img/shoucang.png" alt="" class="w18h14" />
              </div>
              感兴趣
            </div>
          </div>
        </div>
        <div class="flex ptb30">
          <div class="per-w70 mr20 fs-14">
            <div class="mb10" v-if="s_type == 5">
              <span>公司名称：</span>
              <span>{{ info.name }}</span>
            </div>
            <div class="mb10">
              <span>详细地址：</span>
              <span
                >{{ info.province }}{{ info.city }}{{ info.city_area
                }}{{ info.address }}</span
              >
              <!-- <span class="col-CC0000 cup"> 导航到店 > </span> -->
            </div>
          </div>
          <div
            class="per-w30"
            v-if="
              (info.status !== 3 && info.ad_loc == 1) ||
              (info.status !== 3 && info.ad_loc == 2)
            "
          >
            <img
              src="https://pt.baipubang.com/uploads/img/xiangqing/zhang.png"
              alt=""
              class="w146h146"
            />
          </div>
          <div
            class="per-w30"
            v-if="(info.status == 3 && s_type == 3) || (s_type == 5 && info.status == 3)"
          >
            <img
              src="https://pt.baipubang.com/uploads/img/xiangqing/yichengjiao.png"
              alt=""
              class="w146h146"
            />
          </div>
        </div>
        <div class="flex">
          <div class="per-w60 mr7">
            <div class="ptb14lr35 br10 bg-CC0000 col-ffffff flex a-center h70 box-b">
              <div class="per-w30 borr-ffffff text-center fs-20">
                {{ info.contacts || info.name }}
              </div>
              <div class="per-w70 pl30">
                <div class="mr20">
                  {{ info.phone }}
                </div>
              </div>
            </div>
          </div>
          <div class="per-w50">
            <div class="ptb14lr18 br10 bg-EEEEEE fs-14 col-333333 h70 box-b">
              <div>
                <span>客服电话：</span>
                <span class="fwb col-CC0000">{{ info.salesman_phone }}</span>
              </div>
              <div class="mt5">想了解更多信息，请拨打电话！</div>
            </div>
          </div>
        </div>
        <div class="flex a-center j-sb mt14">
          <div></div>
          <div v-if="info.status != 3 && info.status != 10">
            <el-button type="primary" @click="dialogShow(3)" v-if="s_type == 3"
              >确定接</el-button
            >
            <el-button type="danger" @click="dialogShow(2)">乐分享</el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="flex a-center j-sb mt23">
      <div class="flex per-w50">
        <div class="per-w60 mr7">
          <div class="ptb14lr35 br10 bg-CC0000 col-ffffff flex a-center h70 box-b">
            <div class="per-w30 borr-ffffff text-center fs-20">
              {{ info.contacts || info.name }}
            </div>
            <div class="per-w70 pl30">
              <div class="mr20">
                {{ info.phone }}
              </div>
            </div>
          </div>
        </div>
        <div class="per-w40">
          <div class="ptb14lr18 br10 bg-EEEEEE fs-14 col-333333 h70 box-b">
            <div>
              <span>客服电话：</span>
              <span class="fwb col-CC0000">{{ info.salesman_phone }}</span>
            </div>
            <div class="mt5">想了解更多信息，请拨打电话！</div>
          </div>
        </div>
      </div>
      <div class="per-w50 flex a-center j-sb">
        <div></div>
        <div v-if="info.status != 3 && info.status != 10">
          <el-button type="primary" @click="dialogVisible3 = true" v-if="s_type == 3"
            >确定接</el-button
          >
          <el-button type="danger" @click="dialogVisible2 = true">乐分享</el-button>
        </div>
      </div>
    </div> -->

    <div class="borb-E6E6E6 mt50 flex a-center j-sb">
      <div class="flex a-center">
        <div class="h60 w140 flex a-center fs-24 borb-d43030 mr26">详细信息</div>
        <!-- <div
          class="h60 w140 cup flex a-center j-center fs-15 mr5"
          :class="activeStep == index ? 'bg-d43030 col-ffffff' : 'bg-EEEEEE col-333333'"
          v-for="(item, index) in typeList"
          :key="index"
          @click="jump(index)"
        >
          {{ item.name }}
        </div> -->
      </div>
    </div>
    <div class="ptb20 flex">
      <div class="ptb20 per-w80 mr20 scroll-content">
        <div class="scroll-item">
          <div class="col-CC0000 fs-20 mb30 ptb20">详细描述：</div>
          <div v-html="info.content"></div>
        </div>
        <div class="scroll-item" v-if="s_type == 3">
          <div class="col-CC0000 fs-20 mb30 ptb20">物品清单：</div>
          <div>
            <el-table :data="info.goods_list" style="width: 100%">
              <el-table-column prop="category" label="品类"> </el-table-column>
              <el-table-column prop="condition" label="成色"> </el-table-column>
              <el-table-column prop="number" label="数量"> </el-table-column>
              <el-table-column prop="price" label="价格"> </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="scroll-item" v-if="info.video">
          <div class="col-CC0000 fs-20 mb30 ptb20">视频：</div>
          <div>
            <video width="30rem" height="16.875rem" controls :src="info.video"></video>
          </div>
        </div>
        <div class="scroll-item" v-if="bannerImg && bannerImg.length">
          <div class="col-CC0000 fs-20 mb30 ptb20">照片详情：</div>
          <div class="flex f-w">
            <div
              v-for="(item, index) in bannerImg"
              :key="index"
              class="per-w50 box-b pd4 h380"
            >
              <div class="h380 per-w100">
                <img :src="item" alt="" class="h380 per-w100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 相似推荐 -->
      <div class="per-w20 bor-DADADA">
        <div class="h40 l-h40 bg-CC0000 fs-20 col-ffffff text-center">类似店铺</div>
        <ul class="plr5">
          <li
            class="ptb15 borb-DADADA flex cup"
            v-for="(item, index) in recommendList"
            :key="index"
            @click="detailsCut(item)"
          >
            <div class="w116h72 mr5">
              <img :src="item.cover" alt="" class="w116h72" />
            </div>
            <div class="fs-12 col-333333">
              <div class="fs-14 ft-1 w120 fwb">
                {{ item.title }}
              </div>
              <div v-if="item.category">品类：{{ item.category }}</div>
              <div v-if="item.city_area">区域：{{ item.city_area }}</div>
              <div>{{ item.contacts }}：{{ item.phone }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <el-dialog title="温 馨 提 示" :visible.sync="dialogVisible" width="30%">
        <span>是否要取消收藏</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="delecollectClick">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="温 馨 提 示" :visible.sync="dialogVisible4" width="30%">
        <span>是否要举报此店铺</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible4 = false">取 消</el-button>
          <el-button type="primary" @click="jubao">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="乐 分 享" :visible.sync="dialogVisible2" width="30%">
        <span>{{ rich_val.text_e }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="copyFn(info.share_url)">复 制</el-button>
        </span>
      </el-dialog>
      <el-dialog title="确 定 接" :visible.sync="dialogVisible3" width="30%">
        <span>{{ rich_val.text_c }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="Sure_meet()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  old_goods_val,
  acquisition_val,
  similar_goods,
  add_collect,
  del_collect,
  take_over,
  report_shop,
} from "../../utils/api.js";
export default {
  data() {
    return {
      activeStep: 0,
      rich_val: "",
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      typeList: [
        { id: 1, name: "店铺介绍" },
        { id: 2, name: "配套设施" },
        { id: 3, name: "视频看店" },
        { id: 4, name: "照片详情" },
      ],
      type: 1,
      bannerImg: "",
      s_type: "",
      id: "",
      lng: "",
      lat: "",
      province: "",
      city: "",
      info: "",
      recommendList: "",
      pid: "",
      uid: "",
      timer: null,
      imgDistance: 0, // 移动的距离
      allDistance: 0, // 总移动距离
      active: 0,

    };
  },
  computed: {
    imgStyle() {
      return {
        transform: `translate3d(${this.imgDistance}px, 0, 0)`, // 计算移动的距离(x,y,z)
      };
    },
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        this.s_type = this.$route.query.s_type;
        this.id = this.$route.query.id;
        this.pid = this.$route.query.pid;
        console.log(this.id);
        this.getdata();
      },
    },
  },
  mounted() {
    let uid = window.localStorage.getItem("uid");
    if (uid) {
      this.uid = uid;
    } 
    // else {
    //   this.$message.error("还未登录，请登录后再使用");
    //   setTimeout(() => {
    //     this.$router.push({
    //       path: "/login",
    //       query: { navid: 10 },
    //     });
    //     // let routeData = this.$router.resolve({
    //     //   path: "/login",
    //     //   query: { navid: 10 },
    //     // });
    //     // window.open(routeData.href, "_blank");
    //   }, 1500);
    // }
    this.s_type = this.$route.query.s_type;
    this.id = this.$route.query.id;
    this.pid = this.$route.query.pid;
    this.lng = window.localStorage.getItem("lng");
    this.lat = window.localStorage.getItem("lat");
    this.province = window.localStorage.getItem("province");
    this.city = window.localStorage.getItem("city");
    this.getdata();
    this.getrecommend();
  },
  methods: {
    dialogShow(i){
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return ;
      }

      switch (i){
        case 1:
          this.dialogVisible = true;
          break;
        case 2:
          this.dialogVisible2 = true;
          break;
        case 3:
          this.dialogVisible3 = true;
          break;
        case 4:
          this.dialogVisible4 = true;
          break;
        default:
          break;
      }
    },
    clickBtn(e) {
      this.$refs.carousel.setActiveItem(e); // 将tab的下标指定为走马灯的下标
      this.active = e; // tab切换的下标
    },
    changeItem(e) {
      this.active = e; // 将走马灯的 下标 指定为 tab 的下标
      if (this.active < this.bannerImg.length - 1) {
        if (this.active >= 4) {
          // this.allDistance = -80 * (this.active - 4);
          this.allDistance = -128 * (this.active - 4);
          var index = 0;
          const temp = window.setInterval(() => {
            if (index < 64) {
              this.imgDistance -= 2; // 每次向右移动的距离
              index++;
              return;
            } else {
              window.clearInterval(temp);
            }
          }, 10);
        }
      } else if (this.active === this.bannerImg.length - 1) {
        // 到达最后一张图片，再点击跳转回第一张
        setTimeout(() => {
          this.active = 0;
          this.$refs.carousel.setActiveItem(0);
          //  this.active = 0;
          console.log(this.allDistance);
          // this.$refs.carousel.setActiveItem(0);
          // var indexs = 0;
          const temp = window.setInterval(() => {
            // 利用定时器实现图片左右移动的动画效果
            if (this.imgDistance <= 4) {
              // 取绝对值再除
              console.log(this.imgDistance);
              this.imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
              // indexs++;
              return;
            } else {
              window.clearInterval(temp); // 移动完清除定时器
            }
          }, 1);
        }, 2000);
      }
    },
    onScroll(e) {
      let scrollItems = document.querySelectorAll(".scroll-item");

      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离

        let judge =
          e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop;

        if (judge) {
          this.activeStep = i;

          break;
        }
      }
    },
    // 点击切换锚点

    jump(index) {
      let target = document.querySelector(".scroll-content");

      let scrollItems = document.querySelectorAll(".scroll-item");

      // 判断滚动条是否滚动到底部

      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.activeStep = index;
      }

      let total = scrollItems[index].offsetTop - scrollItems[0].offsetTop; // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)

      let distance = document.querySelector(".scroll-content").scrollTop; // 滚动条距离滚动区域顶部的距离

      // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)

      // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次

      // 计算每一小段的距离

      let step = total / 50;

      if (total > distance) {
        smoothDown(document.querySelector(".scroll-content"));
      } else {
        let newTotal = distance - total;

        step = newTotal / 50;

        smoothUp(document.querySelector(".scroll-content"));
      }

      // 参数element为滚动区域

      function smoothDown(element) {
        if (distance < total) {
          distance += step;

          element.scrollTop = distance;

          setTimeout(smoothDown.bind(this, element), 10);
        } else {
          element.scrollTop = total;
        }
      }

      // 参数element为滚动区域

      function smoothUp(element) {
        if (distance > total) {
          distance -= step;

          element.scrollTop = distance;

          setTimeout(smoothUp.bind(this, element), 10);
        } else {
          element.scrollTop = total;
        }
      }

      document.querySelectorAll(".scroll-item").forEach((item, index1) => {
        if (index === index1) {
          item.scrollIntoView({
            block: "start",

            behavior: "smooth",
          });
        }
      });
    },

    jubao() {
      let that = this;
      report_shop({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          that.dialogVisible4 = false;
          that.$alert(res.msg, {
            callback: () => {
              that.getdata();
            },
          });
        })
        .catch((err) => {
          console.log(err);
          that.dialogVisible4 = false;
          that.$alert(err.msg, {
            callback: () => {
              that.getdata();
            },
          });
        });
    },
    Sure_meet() {
      let that = this;
      take_over({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          that.$alert(res.msg, {
            callback: () => {
              that.dialogVisible3 = false;
              that.getdata();
            },
          });
        })
        .catch((err) => {
          console.log(err);
          that.$alert(err.msg, {
            callback: () => {
              that.dialogVisible3 = false;
              that.getdata();
            },
          });
        });
    },
    copyFn(val) {
      console.log(val);
      // createElement() 方法通过指定名称创建一个元素
      var copyInput = document.createElement("input");
      //val是要复制的内容
      copyInput.setAttribute("value", val);
      document.body.appendChild(copyInput);
      copyInput.select();
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyInput);
          this.$message.success("已复制链接,快去分享吧");
          this.dialogVisible2 = false;
        }
      } catch {
        this.$message.error("复制失败，请检查浏览器兼容");
      }
    },
    // 取消收藏
    delecollectClick() {
      let that = this;
      that.dialogVisible = false;
      del_collect({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          this.$alert(res.msg, {
            callback: () => {
              that.getdata();
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.$alert(err.msg, {
            callback: () => {
              that.getdata();
            },
          });
        });
    },
    // 收藏
    collectClick() {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return ;
      }

      let that = this;
      add_collect({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          this.$alert(res.msg, {
            callback: () => {
              that.getdata();
            },
          });
        })
        .catch((err) => {
          this.$alert(err.msg, {
            callback: () => {
              that.getdata();
            },
          });
        });
    },
    // 跳转详情
    detailsCut(item) {
      let routeData = this.$router.resolve({
        path: "/SecondhandGoods",
        query: { navid: 10, s_type: item.s_type, id: item.id },
      });
      window.open(routeData.href, "_blank");
    },
    getdata() {
      var that = this;
      if (that.s_type == 3) {
        old_goods_val({
          uid: that.uid,
          id: that.id,
          pid: that.pid,
        })
          .then((res) => {
            console.log(res);
            let arr = [];
            if (res.data.val.cover) {
              arr.push(res.data.val.cover);
            }
            if (res.data.val.more_img && res.data.val.more_img.length) {
              res.data.val.more_img.forEach((item) => {
                arr.push(item);
              });
            }
            that.bannerImg = arr;
            that.info = res.data.val;
            that.rich_val = res.data.rich_val;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        acquisition_val({
          uid: that.uid,
          id: that.id,
          pid: that.pid,
        })
          .then((res) => {
            let arr = [];
            if (res.data.val.cover) {
              arr.push(res.data.val.cover);
            }
            if (res.data.val.more_img && res.data.val.more_img.length) {
              res.data.val.more_img.forEach((item) => {
                arr.push(item);
              });
            }
            that.bannerImg = arr;
            res.data.val.content = res.data.val.content
              .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
              .replace(/<section/g, "<div")
              .replace(/\/section>/g, "div>");
            that.info = res.data.val;
            that.rich_val = res.data.rich_val;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getrecommend() {
      let that = this;
      let type = 1;
      if (that.s_type == 5) {
        type = 1;
      } else {
        type = 2;
      }
      similar_goods({
        type: type,
        city: that.city,
      })
        .then((res) => {
          console.log(res);
          this.recommendList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.zs-adv {
  #adv-pad-scroll {
    position: relative;
    width: 100%;
    overflow: hidden;
    .adv-pad {
      width: max-content;
      display: flex;
      .adv-pad-item {
        position: relative;
        margin-right: 0.625rem;
        width: 7.0625rem;
        height: 4.625rem;
        cursor: pointer;
        animation: all 1.5s;
        img {
          width: 7.0625rem;
          height: 4.625rem;
          border-radius: 0.25rem;
        }
      }
      .adv-pad-item:hover {
        background-color: rgba(0, 0, 0, 0);
      }
      .adv-pad-item::after {
        content: "";
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        width: 7.0625rem;
        height: 4.625rem;
      }
      .active {
        position: relative;
      }
      .active::after {
        content: "";
        background-color: rgba(0, 0, 0, 0);
        position: absolute;
        top: 0;
        left: 0;
        width: 7.0625rem;
        height: 4.625rem;
      }
    }
  }
}
</style>
